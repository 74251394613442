import React, { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import InformationField from '../../form/components/InformationField';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

import axios from '../../utils/axios.utils';

const QualtricsMetadataDetail: FC = () => {
  const { phaseId, respondentId } = useParams<Record<string, string>>();

  let [qualtricsMetadata, setQualtricsMetadata] = useState<any>();
  let [metadataError, setMetaDataError] = useState<string>('');

  const fetchQualtricsMetadata = async (respondent_id: string, phase_id: string) => {
    await axios
      .get<string, any>(`qualtrics_metadata/${phase_id}?respondent_id=${respondent_id}`)
      .then((response) => {
        setQualtricsMetadata(response?.data?.result);
      })
      .catch((error) => {
        setMetaDataError(error.response.data.message);
      });
  };

  useEffect(() => {
    fetchQualtricsMetadata(respondentId, phaseId);
  }, []);

  const importLogSections = ['values', 'labels', 'displayedFields', 'displayedValues'];

  const capitalizeTitle = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  const DataSection = ({ title, data }: { title: string; data: Record<string, any> }) => {
    if (!data) return null;

    return (
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ backgroundColor: 'lightgreen', padding: 4 }}>
            <Typography variant="h4">{capitalizeTitle(title)}</Typography>
          </Grid>
          {Object.keys(data).map((key) => (
            <Grid item xs={2} key={key}>
              <InformationField label={key} value={Array.isArray(data[key]) ? data[key].join(', ') : data[key]} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container spacing={3} style={{ padding: '1% 2%' }}>
      <AdminPageHeader
        title="Qualtrics Metadata"
        backButton={{ label: `Phase ${phaseId}`, link: `/admin/phases/${phaseId}` }}
      />
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={3}>
            <InformationField label="Respondent Name" value={qualtricsMetadata?.respondent_name} />
          </Grid>
          <Grid item xs={3}>
            <InformationField label="User ID" value={qualtricsMetadata?.respondent_uuid} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card style={{ padding: '1% 2%' }}>
          {metadataError && <p style={{ color: 'red' }}>{metadataError}</p>}
          {qualtricsMetadata?.qualtrics_metadata && (
            <Grid container spacing={3}>
              {Object?.keys(qualtricsMetadata?.qualtrics_metadata)?.map((key, value) => (
                <Grid item xs={6} key={key}>
                  <InformationField label={key} value={qualtricsMetadata?.qualtrics_metadata[key]} />
                </Grid>
              ))}
            </Grid>
          )}
        </Card>
      </Grid>
      <Grid item xs={12}>
        {qualtricsMetadata?.log_data_by_key && (
          <Accordion>
            <AccordionSummary>
              <Typography variant="h4">Qualtrics Data by Key</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {Object.keys(qualtricsMetadata?.log_data_by_key).map((key) => (
                  <Grid item xs={3} key={key}>
                    <InformationField label={key} value={qualtricsMetadata?.log_data_by_key[key]} />
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </Grid>
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary>
            <Typography variant="h4">All Qualtrics Data</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ backgroundColor: 'lightgreen', padding: 4 }}>
                <Typography variant="h4">responseId</Typography>
              </Grid>
              <Grid item xs={12}>
                <InformationField label="responseId" value={qualtricsMetadata?.import_log_data?.responseId} />
              </Grid>
              {importLogSections.map((section) => (
                <DataSection title={section} data={qualtricsMetadata?.import_log_data?.[section]} key={section} />
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default QualtricsMetadataDetail;
