import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Loading, Button } from '@shopify/polaris';
import { Grid, Card, Typography } from '@mui/material';
import { InsightersContext } from '../contexts/InsightersContext';
import FormSkeleton from '../../loading/components/FormSkeleton';
import TerminateInsighterConfirmModal from '../components/TerminateInsighterConfirmModal';
import axios from '../../utils/axios.utils';
import { UserContext } from '../../auth/contexts/UserContext';
import InsighterForm from '../components/InsighterForm';

const EditInsighterPage: React.FunctionComponent = () => {
  const history = useHistory();

  const { insighterId } = useParams<Record<string, string>>();

  const {
    insighter,
    fetchInsighter,
    fetchFormOptions,
    insighterLoading,
    insighterSubmitting,
    setInsighterSubmitting,
    formError,
    setFormError,
  } = useContext(InsightersContext);
  const { currentUserHasRole } = useContext(UserContext);

  const [insighterFetched, setInsighterFetched] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<any>({});
  const [terminateModalOpen, setTerminateModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setFormValues({ ...insighter });
  }, [insighter]);

  const updateInsighter = async () => {
    try {
      setInsighterSubmitting(true);
      axios
        .put<string, any>(`insighters/${insighterId}.json`, {
          insighter: { ...formValues },
        })
        .then(() => {
          setInsighterSubmitting(false);
          history.push(`/admin/insighters/${insighterId}`);
        })
        .catch((error) => {
          setInsighterSubmitting(false);
          const errorMessages = Object.values(error.response.data.error);
          setFormError(errorMessages.join(', '));
        });
    } catch {
      setInsighterSubmitting(false);
      setFormError('Something went wrong, please reload the page and try again.');
    }
  };

  useEffect(() => {
    if (!insighterFetched) {
      fetchFormOptions();
      fetchInsighter(insighterId);
      setInsighterFetched(true);
    }
  }, [insighterFetched, fetchInsighter, insighterId, fetchFormOptions]);

  return insighterLoading ? (
    <>
      <Loading />
      <FormSkeleton />
    </>
  ) : (
    <>
      <TerminateInsighterConfirmModal
        terminateModalOpen={terminateModalOpen}
        setTerminateModalOpen={setTerminateModalOpen}
        insighterId={insighter?.id}
        setInsighterSubmitting={setInsighterSubmitting}
      />
      <Grid container style={{ padding: 15 }}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h1">
            Edit Insighter
          </Typography>
        </Grid>
        <Card style={{ padding: 10 }}>
          <InsighterForm
            formValues={formValues}
            setFormValues={setFormValues}
            insighterSubmitting={insighterSubmitting}
          />
        </Card>

        <Grid item xs={5}>
          <h2 className="Polaris-Heading" style={{ margin: '40px 0 10px' }}>
            Address From Latest Survey
          </h2>
          <p>{insighter?.address_from_qualtrics_survey?.address_line1}</p>
          <p>{insighter?.address_from_qualtrics_survey?.address_line2}</p>
          <p>{`${insighter?.address_from_qualtrics_survey?.city}, ${insighter?.address_from_qualtrics_survey?.state} ${insighter?.address_from_qualtrics_survey?.zip}`}</p>
        </Grid>
        <Grid item xs={5}>
          <h2 className="Polaris-Heading" style={{ margin: '40px 0 10px' }}>
            Address from Smarty API
          </h2>
          <p>{insighter?.recommended_address?.address_line1}</p>
          <p>{insighter?.recommended_address?.address_line2}</p>
          <p>{`${insighter?.recommended_address?.city}, ${insighter?.recommended_address?.state} ${insighter?.recommended_address?.zip}`}</p>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 15 }}>
          {formError && <p style={{ marginBottom: '8px', color: 'red' }}>{formError}</p>}
          <Button primary onClick={() => updateInsighter()} loading={insighterSubmitting}>
            Update Insighter
          </Button>
          &emsp;
          {currentUserHasRole('user_termination') && (
            <Button destructive onClick={() => setTerminateModalOpen(true)} loading={insighterSubmitting}>
              Terminate Insighter
            </Button>
          )}
        </Grid>
      </Grid>
      <br />
    </>
  );
};

export default EditInsighterPage;
