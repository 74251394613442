import React from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import ApplicationProcessBackground from '../assets/ApplicationProcessBackground.png';
import ApplicationImage from '../assets/ApplicationImage.png';
import HoverButton from '../../common/HoverButton';
import FormInput from '../../../form/components/FormInput';
import { InsighterOpportunitiesContext } from '../../../opportunities/contexts/InsighterOpportunitiesContext';
import { Opportunity } from '../../../opportunities/model';

interface ApplicationProcessProps {
  insighterOpportunity: Opportunity;
}

const ApplicationProcess: React.FC<ApplicationProcessProps> = (props) => {
  const { insighterOpportunity } = props;
  const { registerUser, formErrors } = React.useContext(InsighterOpportunitiesContext);

  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [formValues, setFormValues] = React.useState<Record<string, string>>();

  const { search, hash } = useLocation();
  const invitationCode = new URLSearchParams(search).get('invitation_code');

  React.useEffect(() => {
    if (invitationCode?.match(/[A-Z2-9]{10}/) && invitationCode?.length === 10) {
      setFormValues({ ...formValues, invitation_code: invitationCode, invitation_url: window.location.href });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationCode]);

  const handleApply = (formValues) => {
    registerUser(formValues, () =>
      // TODO: Eventually we'll want to add back in the custom query parameter, but no active opportunities use that as of right now
      // window.location.replace(
      //   `${insighterOpportunity.screener_url}?invitation=true&${invitationOpportunity.custom_query_parameter}`,
      // ),

      window.location.replace(`${insighterOpportunity.screener_url}?invitation=true`),
    );
  };

  React.useEffect(() => {
    // Handle scrolling to element with the ID from the hash
    if (hash === '#sign-up-form') {
      const element = document.getElementById('sign-up-form');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);

  const containerStyle = {
    padding: 100,
    backgroundImage: `url(${ApplicationProcessBackground})`,
    backgroundSize: invitationCode ? 'cover' : 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    marginBottom: 10,
    cursor: 'pointer',
  };

  // Media query to adjust for mobile screens
  const mobileStyle = {
    padding: '20px',
    backgroundImage: 'none', // Hide background image on mobile
  };

  React.useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      if (newWidth !== windowWidth) {
        setWindowWidth(newWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [windowWidth]);

  return insighterOpportunity ? (
    <Grid container spacing={4} style={{ maxWidth: 1200, margin: 'auto', padding: 10 }} id="sign-up-form">
      <Grid item xs={12} style={{ ...containerStyle, ...(windowWidth <= 960 ? mobileStyle : {}) }}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            style={{ ...(windowWidth <= 960 ? { textAlign: 'center' } : { textAlign: 'left' }) }}
          >
            <img
              src={insighterOpportunity.content_image_url ? insighterOpportunity.content_image_url : ApplicationImage}
              alt="opportunity"
              style={{
                width: '80%',
                height: 'auto',
                objectFit: 'cover',
                maxWidth: 400,
                margin: 'auto',
                display: 'inline-block',
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: 600, margin: 'auto' }}>
              <h2
                style={{
                  color: '#3B4255',
                  fontFamily: 'Crimson Text',
                  fontSize: '28px',
                  fontStyle: 'normal',
                  lineHeight: '150%',
                  fontWeight: 600,
                  borderBottom: '1px solid #545D78',
                  marginBottom: 5,
                  paddingBottom: 5,
                }}
              >
                Application
              </h2>
              <div
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{ __html: insighterOpportunity.learn_more }}
                style={{ marginBottom: 10 }}
                className="uploaded-content"
              />
              {invitationCode ? (
                <div style={{ marginTop: '20px' }}>
                  <Grid container justifyContent="flex-start">
                    <Grid item md={10} xs={10}>
                      <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                          <FormInput
                            type="text"
                            label="First Name"
                            value={formValues?.first_name}
                            onChange={(value) => setFormValues({ ...formValues, first_name: value })}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <FormInput
                            type="text"
                            label="Last Name"
                            value={formValues?.last_name}
                            onChange={(value) => setFormValues({ ...formValues, last_name: value })}
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <FormInput
                            type="email"
                            label="Email"
                            value={formValues?.email}
                            onChange={(value) => setFormValues({ ...formValues, email: value })}
                          />
                        </Grid>
                        {formValues?.invitation_code && (
                          <Grid item xs={12}>
                            <FormInput
                              type="text"
                              label="Invitation Code"
                              value={formValues?.invitation_code}
                              disabled
                            />
                          </Grid>
                        )}
                      </Grid>
                      <Grid container spacing={4}>
                        <Grid item xs={12} style={{ padding: '6px 16px' }}>
                          <p style={{ opacity: 0.7, fontSize: '11px' }}>
                            By clicking Apply, you agree to our{' '}
                            <a target="_blank" href="/terms-of-use">
                              Terms of Use
                            </a>
                            ,{' '}
                            <a target="_blank" href="/pay-portal-terms">
                              Pay Portal Terms
                            </a>
                            ,{' '}
                            <a target="_blank" href="/privacy-policy">
                              Privacy Policy
                            </a>
                            , and{' '}
                            <a target="_blank" href="/cookie-policy">
                              Cookie Policy.
                            </a>
                          </p>
                        </Grid>
                        <p>formValues: {JSON.stringify(formValues, null, 2)}</p>
                        <Grid item style={{ marginBottom: '20px' }}>
                          <p style={{ color: 'red' }}>{formErrors?.base}</p>
                          <HoverButton
                            text="Start Application"
                            externalLink={false}
                            onClick={() => handleApply(formValues)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              ) : (
                <HoverButton text="Apply Now" link={insighterOpportunity.screener_url} />
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

export default ApplicationProcess;
