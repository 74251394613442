import React from 'react';
import { Button, Loading, Page, Toast } from '@shopify/polaris';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import ConditionAutocompleteInput from '../../form/components/ConditionAutocompleteInput';
import UngroupedAutocompleteInput from '../../form/components/UngroupedAutocompleteInput';
import FormInput from '../../form/components/FormInput';
import SelectInput from '../../form/components/SelectInput';
import DataTableSkeleton from '../../loading/components/DataTableSkeleton';
import BasicFooter from '../../navigation/components/BasicFooter';
import { RegistrationsContext } from '../contexts/RegistrationsContext';
import { ProfileFormValues } from '../model';
import states from '../../insighters/constants/states';
import degreeChoices from '../../insighters/constants/degreeChoices';

const OptionalHealthInformation: React.FunctionComponent = () => {
  const [userLoading] = React.useState(false);
  const history = useHistory();
  const { updateProfile, fetchFormOptions, formOptions, formError, profileSubmitting } =
    React.useContext(RegistrationsContext);

  const [formOptionsFetched, setFormOptionsFetched] = React.useState(false);
  const loadingMarkup = userLoading ? <Loading /> : null;
  const [toastActive, setToastActive] = React.useState(false);
  const toggleToastActive = React.useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const [formValues, setFormValues] = React.useState<ProfileFormValues>({});

  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  React.useEffect(() => {
    if (!formOptionsFetched) {
      fetchFormOptions();
      setFormOptionsFetched(true);
    }
  }, [formOptionsFetched, fetchFormOptions, setFormOptionsFetched]);

  const displayHealthcareProviderFields = () => {
    const usedRoles = formOptions?.insighter_role_options.filter((option) => {
      return formValues?.insighter_role_names?.includes(option.label);
    });
    const isHCP = (role) => role.hcp_role === true;
    return usedRoles?.some(isHCP);
  };

  const actualPageMarkup = (
    <Page fullWidth>
      <Grid container justifyContent="center">
        <Grid item xs={10} style={{ marginTop: 25 }}>
          <h2
            className="Polaris-Header-Title"
            style={{ fontFamily: 'Cooper', fontSize: '36px', margin: '0 10px 10px 0', lineHeight: '40px' }}
          >
            Sign Up &mdash; Optional Information
          </h2>
          <Grid container>
            <h2 className="Polaris-Heading" style={{ marginTop: 10 }}>
              Additional Profile Information
            </h2>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
              <FormInput
                type="text"
                label="Phone"
                value={formValues.mobile_phone}
                onChange={(value) => setFormValues({ ...formValues, mobile_phone: value })}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormInput
                type="text"
                label="Zipcode"
                value={formValues.zip}
                onChange={(value) => setFormValues({ ...formValues, zip: value })}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormInput
                type="text"
                label="Date of Birth"
                value={formValues.date_of_birth}
                onChange={(value) => setFormValues({ ...formValues, date_of_birth: value })}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SelectInput
                label="Contact Method"
                choices={[
                  { label: 'Email', value: 'Email' },
                  { label: 'Phone Call', value: 'Phone Call' },
                  { label: 'Text Message', value: 'Text Message' },
                ]}
                onChange={(value) => setFormValues({ ...formValues, preferred_contact_method: value })}
                value={formValues.preferred_contact_method}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Grid container>
            <h2 className="Polaris-Heading" style={{ marginTop: 10 }}>
              Health Information
            </h2>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Grid container spacing={4}>
            {formOptions?.insighter_role_options?.length > 0 && (
              <Grid item xs={12}>
                <UngroupedAutocompleteInput
                  label="Healthcare Role"
                  choices={formOptions?.insighter_role_options}
                  onChange={(value) => {
                    setFormValues({ ...formValues, insighter_role_names: value });
                  }}
                  value={formValues.insighter_role_names as any[]}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <br />
      {displayHealthcareProviderFields() && (
        <>
          <Grid container>
            <Grid item xs={1} />
            <Grid item xs={10}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Grid container>
                    <h2 className="Polaris-Heading" style={{ margin: '40px 0 10px' }}>
                      Health Care Provider Information
                    </h2>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    {formOptions?.specialty_options?.length > 0 && (
                      <Grid item xs={12}>
                        <UngroupedAutocompleteInput
                          label="Specialties"
                          choices={formOptions?.specialty_options}
                          onChange={(value) => {
                            setFormValues({ ...formValues, specialty_names: value });
                          }}
                          value={formValues.specialty_names as any[]}
                        />
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <FormInput
                        type="text"
                        label="NPI Number"
                        value={formValues.npi_number}
                        onChange={(value) => setFormValues({ ...formValues, npi_number: value })}
                      />
                      {formValues?.npi_number && formValues?.npi_number.length !== 10 && (
                        <p style={{ color: 'red' }}>NPI Number must be 10 digits.</p>
                      )}
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={6}>
                      <SelectInput
                        label="Healthcare Certification or Degree"
                        choices={degreeChoices}
                        onChange={(value) =>
                          setFormValues({ ...formValues, healthcare_certification_or_degree: value })
                        }
                        value={formValues.healthcare_certification_or_degree}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      {formValues.healthcare_certification_or_degree === 'Other (please specify)' && (
                        <FormInput
                          type="text"
                          label="Specify Other Degree"
                          value={formValues.other_certifications}
                          onChange={(value) => setFormValues({ ...formValues, other_certifications: value })}
                        />
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {/* Temporarily disabled until a bug can be fixed */}
                      {/* <UngroupedAutocompleteInput
                        label="Practicing In"
                        choices={states}
                        onChange={(value) => {
                          setFormValues({ ...formValues, states_practicing: value });
                        }}
                        value={formValues?.states_practicing}
                      /> */}
                    </Grid>
                    <Grid item xs={6}>
                      <FormInput
                        type="text"
                        label="Medical School Graduation Year"
                        value={formValues.med_school_graduation_year}
                        onChange={(value) => setFormValues({ ...formValues, med_school_graduation_year: value })}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectInput
                        label="% of Patient Care"
                        choices={[
                          { label: '0%', value: '0' },
                          { label: '20%', value: '20' },
                          { label: '40%', value: '40' },
                          { label: '60%', value: '60' },
                          { label: '80%', value: '80' },
                          { label: '100%', value: '100' },
                        ]}
                        onChange={(value) => setFormValues({ ...formValues, percent_patient_care: value })}
                        value={formValues.percent_patient_care}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <FormInput
                        type="text"
                        label="Residency End Year"
                        value={formValues.residency_end_year}
                        onChange={(value) => setFormValues({ ...formValues, residency_end_year: value })}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormInput
                        type="text"
                        label="License Start Year"
                        value={formValues.license_start_year}
                        onChange={(value) => setFormValues({ ...formValues, license_start_year: value })}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectInput
                        label="Board Certified"
                        choices={[
                          { label: 'Yes', value: 'Yes' },
                          { label: 'No', value: 'No' },
                          { label: 'N/A', value: 'N/A' },
                        ]}
                        onChange={(value) => setFormValues({ ...formValues, board_certified: value })}
                        value={formValues.board_certified}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectInput
                        label="Board Eligible"
                        choices={[
                          { label: 'Yes', value: 'Yes' },
                          { label: 'No', value: 'No' },
                          { label: 'N/A', value: 'N/A' },
                        ]}
                        onChange={(value) => setFormValues({ ...formValues, board_eligible: value })}
                        value={formValues.board_eligible}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
        </>
      )}
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <h2 className="Polaris-Heading" style={{ marginTop: 40 }}>
            Conditions
          </h2>

          <ConditionAutocompleteInput
            label={null}
            onChange={(value) => {
              setFormValues({ ...formValues, condition_names: value });
            }}
            value={formValues.condition_names as any[]}
          />
        </Grid>
        <Grid item xs={12}>
          <br />
        </Grid>
        <Grid item xs={10}>
          <Grid container>
            <h2 className="Polaris-Heading" style={{ marginTop: 10 }}>
              Diversity, Equity, and Inclusion
            </h2>
            <br />
            <p style={{ marginTop: 15 }}>
              At The Insighters, we believe that diverse research is better research. We believe that research is more
              trustworthy when we work together to understand & represent different cultures & backgrounds, & we are
              committed to cultivating an inclusive & diverse community. This is where you come in! If you&apos;re
              comfortable disclosing, please let us know your following information.
            </p>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
              <SelectInput
                label="Gender"
                choices={[
                  { label: 'Female', value: 'Female' },
                  { label: 'Male', value: 'Male' },
                  { label: 'Non-binary', value: 'Non-binary' },
                  { label: 'Not Listed', value: 'Not Listed' },
                  { label: 'Other', value: 'Other' },
                  { label: 'Withhold / Decline to Disclose', value: 'Withhold / Decline to Disclose' },
                ]}
                onChange={(value) => setFormValues({ ...formValues, gender: value })}
                value={formValues.gender}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SelectInput
                label="Do you identify as transgender?"
                choices={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                  { label: 'Not Sure', value: 'Not Sure' },
                  { label: 'Prefer Not to Say', value: 'Prefer Not to Say' },
                ]}
                onChange={(value) => setFormValues({ ...formValues, transgender_identity: value })}
                value={formValues.transgender_identity}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SelectInput
                label="Sexual Orientation"
                choices={[
                  { label: 'Gay', value: 'Gay' },
                  { label: 'Lesbian', value: 'Lesbian' },
                  { label: 'Bisexual', value: 'Bisexual' },
                  { label: 'Heterosexual', value: 'Heterosexual' },
                  { label: 'Queer', value: 'Queer' },
                  { label: 'Other', value: 'Other' },
                  { label: 'Withhold / Decline to Disclose', value: 'Withhold / Decline to Disclose' },
                ]}
                onChange={(value) => setFormValues({ ...formValues, sexuality: value })}
                value={formValues.sexuality}
              />
            </Grid>
            {formOptions?.ethnicity_options?.length > 0 && (
              <Grid item md={6} xs={12}>
                <UngroupedAutocompleteInput
                  label="Race/Ethnicity"
                  choices={formOptions?.ethnicity_options}
                  onChange={(value) => {
                    setFormValues({ ...formValues, ethnicity_names: value });
                  }}
                  value={formValues.ethnicity_names}
                />
              </Grid>
            )}
            <Grid item md={6} xs={12}>
              <SelectInput
                label="Veteran Status"
                choices={[
                  { label: 'I am Active Duty in the U.S. Military', value: 'I am Active Duty in the U.S. Military' },
                  { label: 'I have served in the U.S. Military', value: 'I have served in the U.S. Military' },
                  { label: 'I am a U.S. Military Spouse', value: 'I am a U.S. Military Spouse' },
                  { label: 'I have not served in the U.S. Military', value: 'I have not served in the U.S. Military' },
                  { label: 'Withhold / Decline to Disclose', value: 'Withhold / Decline to Disclose' },
                ]}
                onChange={(value) => setFormValues({ ...formValues, veteran_status: value })}
                value={formValues.veteran_status}
              />
            </Grid>
          </Grid>
          <br />
          {formError && <p style={{ color: 'red', marginBottom: '8px' }}>{formError}</p>}
          <div style={{ textAlign: 'left', display: 'flex' }}>
            <div style={{ margin: '0 15px 15px 0' }}>
              <Button outline onClick={() => history.push('/opportunities')}>
                Skip For Now
              </Button>
            </div>
            <div>
              <Button primary loading={profileSubmitting} onClick={() => updateProfile('0', formValues, false)}>
                Submit Optional Information
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </Page>
  );

  const loadingPageMarkup = <DataTableSkeleton />;

  const pageMarkup = userLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <div style={{ position: 'relative', minHeight: '100vh', padding: 0 }}>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
      <BasicFooter />
    </div>
  );
};

export default OptionalHealthInformation;
