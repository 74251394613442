import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import {
  Project,
  ProjectFormValues,
  ProjectLaunchChecklist,
  ProjectWrapUpChecklist,
  ProjectLaunchChecklistFormValues,
  ProjectWrapUpChecklistFormValues,
} from '../model';
import { SelectOption } from '../../form/model';

interface ProjectsContextInterface {
  fetchProjects?: (params?: string) => Promise<void>;
  projects?: Project[];
  projectsDownload?: Record<string, any>[];
  fetchProject?: (project_id: string) => Promise<void>;
  project?: Project;
  projectFetched?: boolean;
  projectLoading?: boolean;

  statusFilter?: string;
  setStatusFilter?: React.Dispatch<React.SetStateAction<string>>;

  updateProject?: (project_id: string, formValues: ProjectFormValues) => Promise<void>;
  createProject?: (formValues: ProjectFormValues) => Promise<void>;
  fetchFormOptions?: () => Promise<void>;
  formOptions?: Record<string, SelectOption[]>;
  projectSubmitting?: boolean;

  filterParams?: string;
  setFilterParams?: React.Dispatch<React.SetStateAction<string>>;

  sortParams?: string;
  setSortParams?: React.Dispatch<React.SetStateAction<string>>;

  totalRecords?: number;

  publishProject?: (project_id: string) => Promise<void>;
  errorMessage?: string;

  fetchProjectLaunchChecklist?: (project_id: string) => Promise<void>;
  updateProjectLaunchChecklist?: (project_id: string, formValues: ProjectLaunchChecklistFormValues) => Promise<void>;
  projectLaunchChecklist?: ProjectLaunchChecklist;

  fetchProjectWrapUpChecklist?: (project_id: string) => Promise<void>;
  updateProjectWrapUpChecklist?: (project_id: string, formValues: ProjectWrapUpChecklistFormValues) => Promise<void>;
  completeProjectWrapUpChecklist?: (project_id: string) => Promise<void>;
  projectWrapUpChecklist?: ProjectWrapUpChecklist;
  archiveProject?: (opportunity_id: string) => Promise<void>;

  sortProjects?: (sortValues: string) => void;
}

const ProjectsContext = React.createContext<ProjectsContextInterface>({
  fetchProjects: undefined,
  projects: [],
  fetchProject: undefined,
  project: {},
});

const ProjectsContextConsumer = ProjectsContext.Consumer;
const ProjectsContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [projectLoading, setProjectLoading] = useState(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [project, setProject] = useState<Project>({});
  const [projectLaunchChecklist, setProjectLaunchChecklist] = useState<ProjectLaunchChecklist>({});
  const [projectWrapUpChecklist, setProjectWrapUpChecklist] = useState<ProjectWrapUpChecklist>({});
  const [statusFilter, setStatusFilter] = useState('');
  const [projectFetched, setProjectFetched] = useState<boolean>(false);
  const [projectSubmitting, setProjectSubmitting] = useState<boolean>(false);
  const [formOptions, setFormOptions] = useState<Record<string, SelectOption[]>>({ project_options: [] });
  const [totalRecords, setTotalRecords] = useState<number>();
  const [sortParams, setSortParams] = useState<string>('sort=');
  const [filterParams, setFilterParams] = useState<string>('ransack[query]=');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [projectsDownload, setProjectsDownload] = React.useState<Record<string, any>[]>();

  const fetchProjects = async (params = '') => {
    setProjectLoading(true);

    const response = await axios.get<string, any>(`projects?${params}`);

    setProjectLoading(false);
    setProjects(response?.data?.result);
    setStatusFilter(response?.data?.applied_filters?.status?.value);
    setTotalRecords(response?.data?.total_records);
    setProjectsDownload(response?.data?.csv_data);
  };

  const fetchProject = async (project_id: string) => {
    setProjectLoading(true);

    const response = await axios.get<string, any>(`projects/${project_id}.json`);

    setProjectLoading(false);

    setProjectFetched(true);
    setProject(response?.data?.result);
  };

  const fetchFormOptions = async () => {
    const response = await axios.get<string, any>(`projects/new.json`);

    setFormOptions(response?.data);
  };

  const updateProject = async (project_id: string, formValues: ProjectFormValues) => {
    setProjectSubmitting(true);

    await axios
      .put<string, any>(`projects/${project_id}.json`, {
        project: { ...formValues },
      })
      .then(() => {
        setProjectSubmitting(false);
        history.push(`/admin/projects/${project.id}`);
      })
      .catch((error) => {
        setProjectSubmitting(false);
        const errorMessages = Object.values(error.response.data.error);
        setErrorMessage(errorMessages.join(', '));
      });
  };

  const createProject = async (formValues: ProjectFormValues) => {
    try {
      setProjectSubmitting(true);
      axios
        .post<string, any>(`projects.json`, {
          project: { ...formValues },
        })
        .then((response) => {
          setProjectSubmitting(false);
          history.push(`/admin/projects/${response.data.result.id}`);
        })
        .catch((error) => {
          setProjectSubmitting(false);
          const errorMessages = Object.values(error.response.data.message);
          setErrorMessage(errorMessages.join(', '));
        });
    } catch {
      setProjectSubmitting(false);
      setErrorMessage('Something went wrong, please reload the page and try again.');
    }
  };

  const publishProject = async (project_id: string) => {
    setProjectSubmitting(true);

    await axios
      .put<string, any>(`projects/${project_id}.json`, {
        project: { status: 'published' },
      })
      .then(() => {
        setProjectSubmitting(false);
        history.push(`/admin/projects/${project.id}`);
      })
      .catch((error) => {
        setProjectSubmitting(false);
        const errorMessages = Object.values(error.response.data.error);
        setErrorMessage(errorMessages.join(', '));
      });
  };

  const fetchProjectLaunchChecklist = async (project_id: string) => {
    setProjectLoading(true);

    const response = await axios.get<string, any>(`project_launch_checklists/${project_id}.json`);

    setProjectLoading(false);

    setProjectFetched(true);
    setProjectLaunchChecklist(response?.data?.result);
  };

  const updateProjectLaunchChecklist = async (project_id: string, formValues: ProjectLaunchChecklistFormValues) => {
    setProjectSubmitting(true);

    await axios.put<string, any>(`project_launch_checklists/${project_id}.json`, {
      project_launch_checklist: { ...formValues },
    });

    setProjectSubmitting(false);
    history.push(`/admin/projects/${project.id}/launch-checklist`);
  };

  const fetchProjectWrapUpChecklist = async (project_id: string) => {
    setProjectLoading(true);

    const response = await axios.get<string, any>(`project_wrap_up_checklists/${project_id}.json`);

    setProjectLoading(false);

    setProjectFetched(true);
    setProjectWrapUpChecklist(response?.data?.result);
  };

  const updateProjectWrapUpChecklist = async (project_id: string, formValues: ProjectWrapUpChecklistFormValues) => {
    setProjectSubmitting(true);

    await axios.put<string, any>(`project_wrap_up_checklists/${project_id}.json`, {
      project_wrap_up_checklist: { ...formValues },
    });

    setProjectSubmitting(false);
    history.push(`/admin/projects/${project.id}/wrap-up-checklist`);
  };

  const completeProjectWrapUpChecklist = async (project_id: string) => {
    setProjectSubmitting(true);

    await axios.put<string, any>(`project_wrap_up_checklists/${project_id}.json`, {
      project_wrap_up_checklist: { project_status: 'complete' },
    });

    await axios.put<string, any>(`projects/${project_id}.json`, {
      project: { status: 'inactive' },
    });

    setProjectSubmitting(false);
    history.push(`/admin/projects/${project.id}`);
  };

  const archiveProject = async (project_id: string) => {
    setProjectSubmitting(true);

    await axios.put<string, any>(`projects/${project_id}.json`, {
      project: { status: 'archived', update_status: 'false' },
    });

    setProjectSubmitting(false);

    if (project.client_name === 'Sommer Consulting') {
      history.push('/admin/internal-projects');
    } else {
      history.push('/admin/client-projects');
    }
  };

  const sortProjects = (sortValues: string) => {
    axios.post('/projects/sort.json', {
      sorting: sortValues,
      headers: { 'Content-Type': 'application/json' },
    });
  };

  return (
    <ProjectsContext.Provider
      value={{
        fetchProjects,
        projects,
        projectsDownload,
        fetchProject,
        project,
        projectFetched,
        projectLoading,

        statusFilter,
        setStatusFilter,

        createProject,
        updateProject,
        fetchFormOptions,
        formOptions,
        projectSubmitting,

        totalRecords,
        sortParams,
        setSortParams,
        filterParams,
        setFilterParams,

        publishProject,
        errorMessage,

        fetchProjectLaunchChecklist,
        updateProjectLaunchChecklist,
        projectLaunchChecklist,

        fetchProjectWrapUpChecklist,
        updateProjectWrapUpChecklist,
        completeProjectWrapUpChecklist,
        projectWrapUpChecklist,
        archiveProject,

        sortProjects,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};

export { ProjectsContextProvider, ProjectsContextConsumer, ProjectsContext };
