import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Modal, TextContainer } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import { DashboardInterview } from '../model';
import ButtonWrapper from '../../form/components/ButtonWrapper';
import DefaultOpportunityImage from '../../../assets/default-opportunity-image.png';
import axios from '../../utils/axios.utils';

interface InterviewDashboardCardProps {
  interview?: DashboardInterview;
}

const ScheduledTimeDisplay = (scheduledTime) => {
  // Parse the ISO 8601 string into a Date object
  const date = new Date(scheduledTime);

  // Format the date to include the local time and abbreviated time zone
  const localTimeString = date.toLocaleString(undefined, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'short', // Adds the time zone abbreviation (e.g., EST, PST)
  });

  return <div>{localTimeString}</div>;
};

const InterviewDashboardCard: React.FC<InterviewDashboardCardProps> = (props) => {
  const { interview } = props;

  const [openDetailsModal, setOpenDetailsModal] = React.useState(false);

  return (
    <Card>
      <Modal open={openDetailsModal} onClose={() => setOpenDetailsModal(false)} title={interview.opportunity_name}>
        <Modal.Section>
          <TextContainer>
            <p style={{ whiteSpace: 'break-spaces' }}>{interview.opportunity_learn_more}</p>
          </TextContainer>
        </Modal.Section>
        <hr style={{ height: 1, backgroundColor: '#ddd', border: 'none' }} />
        <Grid container spacing={1} justify="flex-end" style={{ paddingRight: 15, paddingBottom: 10 }}>
          <Grid item>
            <ButtonWrapper onClick={() => setOpenDetailsModal(false)} label="Close" />
          </Grid>
        </Grid>
      </Modal>

      <Grid container style={{ margin: '40px 0', display: 'flex', alignItems: 'center' }} justifyContent="center">
        {interview.s3_image_url ? (
          <Grid item xs={12} md={6} lg={4}>
            <div className="Polaris-MediaCard">
              <div className="Polaris-MediaCard__MediaContainer" style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  alt=""
                  width="100%"
                  height="auto"
                  src={interview.s3_image_url}
                  style={{ objectFit: 'cover', objectPosition: 'center center' }}
                />
              </div>
              <div className="Polaris-MediaCard__InfoContainer" style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  className="Polaris-Card__Section"
                  style={{ display: 'flex', alignItems: 'center', height: '100%' }}
                >
                  <div className="Polaris-Stack Polaris-Stack--vertical Polaris-Stack--spacingTight">
                    <div className="Polaris-Stack__Item">
                      <div className="Polaris-MediaCard__Heading">
                        <h2 className="Polaris-Heading" style={{ fontSize: 20 }}>
                          {interview?.opportunity_name}
                        </h2>
                      </div>
                    </div>
                    <div className="Polaris-Stack__Item">
                      <div className="Polaris-MediaCard__ActionContainer">
                        <div className="Polaris-ButtonGroup">
                          <div className="Polaris-ButtonGroup__Item">
                            <div className="Polaris-MediaCard__PrimaryAction">
                              {interview?.opportunity_learn_more && (
                                <button
                                  className="Polaris-Button"
                                  type="button"
                                  onClick={() => setOpenDetailsModal(true)}
                                >
                                  <span className="Polaris-Button__Content">
                                    <span className="Polaris-Button__Text">Learn More</span>
                                  </span>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        ) : (
          <Grid item xs={12} md={6} lg={4} style={{ display: 'flex' }}>
            <img
              alt=""
              width="100%"
              height="auto"
              src={DefaultOpportunityImage}
              style={{ objectFit: 'cover', objectPosition: 'center center' }}
            />
          </Grid>
        )}

        <Grid style={{ borderLeft: '1px solid #ccc', paddingLeft: 30 }} item xs={12} md={6} lg={8}>
          <h2 style={{ marginTop: 20 }} className="Polaris-Heading">
            Interview Details
          </h2>

          <Grid container style={{ marginLeft: -20 }}>
            <Grid item xs={4}>
              <Card.Section title="Interview Date">
                <p>{interview.scheduled_date}</p>
              </Card.Section>
            </Grid>
            <Grid item xs={4}>
              <Card.Section title="Interview Time">
                <p>{ScheduledTimeDisplay(interview.scheduled_time)}</p>
              </Card.Section>
            </Grid>
            <Grid item xs={4}>
              <Card.Section title="Payment Amount">
                <p>{interview.payment_amount}</p>
              </Card.Section>
            </Grid>
            <Grid item xs={4}>
              <Card.Section title="Payment Option">
                <p>{interview?.display_payment_option}</p>
              </Card.Section>
            </Grid>
            <Grid item xs={8}>
              <Card.Section title="Interview Contact">
                <p>hello@theinsighters.com</p>
              </Card.Section>
            </Grid>
            <Grid>
              <Card.Section title="Location">
                {interview?.address_line1 ? (
                  <>
                    <p>{interview?.address_line1}</p>
                    <p>{interview?.address_city_state_zip}</p>
                  </>
                ) : (
                  <>
                    <p>Virtual</p>
                  </>
                )}
              </Card.Section>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default InterviewDashboardCard;
