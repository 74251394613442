import React, { FC, useContext } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';

import { CSVLink } from 'react-csv';

import { useHistory } from 'react-router-dom';
import ClientProjectFilterBar from '../components/ClientProjectFilterBar';
import ClientProjectsList from '../components/ClientProjectsList';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

import { ProjectsContext } from '../contexts/ProjectsContext';

const ClientProjectsListContainer: React.FC = () => {
  const history = useHistory();

  let { projectsDownload } = useContext(ProjectsContext);

  const ProjectAdminButtons: FC = () => (
    <>
      <Button className="primary-btn" onClick={() => history.push('/admin/projects/new')}>
        Create Project
      </Button>
      {projectsDownload?.length > 0 && (
        <CSVLink data={projectsDownload} filename="Projects" style={{ textDecoration: 'none' }}>
          <Button className="primary-btn">Download Projects</Button>
        </CSVLink>
      )}
    </>
  );

  // Top line grid to inline-block

  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader title="Client Projects" adminButtons={<ProjectAdminButtons />} />
      <Card style={{ padding: '1% 2%' }}>
        <ClientProjectFilterBar financial={false} />
        <ClientProjectsList />
      </Card>
    </Grid>
  );
};

export default ClientProjectsListContainer;
